import React, { useEffect, useState } from "react";
import awsIot from "aws-iot-device-sdk";

import { useGoogleAnalytics } from "../hooks/google-analytics";
import { useWindowSize } from "../hooks/window-size";

import styles from "./app.module.scss";

import logo from "../media/logo.svg";

/**
 * AWS IAM "AWSIoTWeatherSubscriberAccess" Policy
 *
 * {
 *     "Version": "2012-10-17",
 *     "Statement": [
 *         {
 *             "Effect": "Allow",
 *             "Action": "iot:Connect",
 *             "Resource": "arn:aws:iot:us-east-1:369769114943:client/subscriber-*"
 *         },
 *         {
 *             "Effect": "Allow",
 *             "Action": "iot:Publish",
 *             "Resource": "arn:aws:iot:us-east-1:369769114943:topic/$aws/things/weather/shadow/get"
 *         },
 *         {
 *             "Effect": "Allow",
 *             "Action": "iot:Subscribe",
 *             "Resource": [
 *                 "arn:aws:iot:us-east-1:369769114943:topicfilter/$aws/things/weather/shadow/get/accepted",
 *                 "arn:aws:iot:us-east-1:369769114943:topicfilter/$aws/things/weather/shadow/update/accepted"
 *             ]
 *         },
 *         {
 *             "Effect": "Allow",
 *             "Action": "iot:Receive",
 *             "Resource": [
 *                 "arn:aws:iot:us-east-1:369769114943:topic/$aws/things/weather/shadow/get/accepted",
 *                 "arn:aws:iot:us-east-1:369769114943:topic/$aws/things/weather/shadow/update/accepted"
 *             ]
 *         }
 *     ]
 * }
 */
const device = awsIot.device({
    protocol: "wss",
    host: process.env.REACT_APP_AWS_IOT_MQTT_HOST,
    clientId: process.env.REACT_APP_AWS_IOT_MQTT_CLIENT_ID_PREFIX + Math.random().toString(36).substring(7),
    accessKeyId: process.env.REACT_APP_AWS_IOT_MQTT_ACCESS_KEY,
    secretKey: process.env.REACT_APP_AWS_IOT_MQTT_SECRET_KEY,
});

function convertIcon(icon) {
    return icon != null ? `wi-${icon.replace(/_/g, "-").toLowerCase()}.svg` : "wi-na.svg";
}

export default function App() {
    const [timestamp, setTimestamp] = useState();
    const [barometricPressure, setBarometricPressure] = useState();
    const [humidity, setHumidity] = useState();
    const [dailyRainfall, setDailyRainfall] = useState();
    const [solarRadiation, setSolarRadiation] = useState();
    const [temperature, setTemperature] = useState();
    const [dewPoint, setDewPoint] = useState();
    const [windSpeed, setWindSpeed] = useState();
    const [windDirection, setWindDirection] = useState();
    const [windGustSpeed, setWindGustSpeed] = useState();
    const [windGustDirection, setWindGustDirection] = useState();

    const [forecast, setForecast] = useState();

    useGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

    const size = useWindowSize();

    useEffect(() => {
        device.on("connect", () => {
            device.subscribe(`$aws/things/${process.env.REACT_APP_AWS_IOT_MQTT_THING}/shadow/get/accepted`);
            device.subscribe(`$aws/things/${process.env.REACT_APP_AWS_IOT_MQTT_THING}/shadow/update/accepted`);

            device.publish(`$aws/things/${process.env.REACT_APP_AWS_IOT_MQTT_THING}/shadow/get`);
        });

        device.on("message", (topic, payload) => {
            console.log("message", topic, payload.toString());

            const response = JSON.parse(payload);

            const current = response.state.reported.current;
            if (current) {
                current.timestamp != null && setTimestamp(current.timestamp);
                current.barometricPressure != null && setBarometricPressure(current.barometricPressure);
                current.humidity != null && setHumidity(current.humidity);
                current.dailyRainfall != null && setDailyRainfall(current.dailyRainfall);
                current.solarRadiation != null && setSolarRadiation(current.solarRadiation);
                current.temperature != null && setTemperature(current.temperature);
                current.dewPoint != null && setDewPoint(current.dewPoint);
                current.windSpeed != null && setWindSpeed(current.windSpeed);
                current.windDirection != null && setWindDirection(current.windDirection);
                current.windGustSpeed != null && setWindGustSpeed(current.windGustSpeed);
                current.windGustDirection != null && setWindGustDirection(current.windGustDirection);
            }

            const forecast = response.state.reported.forecast;
            if (forecast != null) {
                setForecast(forecast);
            }
        });
    }, []); // Run useEffect Only Once

    return (
        <>
            <div className={styles["container"]}>
                <header className={styles["header"]}>
                    <img alt="Kimu" className={styles["logo"]} src={logo} />
                    <h2>Olabarrieta Weather Conditions</h2>
                    {timestamp && (
                        <p className={styles["updated"]}>
                            {size && size.width > 576 && "Last Updated "}
                            {new Date(timestamp * 1000).toLocaleString("en-ES", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                            })}
                        </p>
                    )}
                </header>
                <div className={styles["overview"]}>
                    <div className={styles["icon"]}>
                        <img
                            alt={forecast && forecast.weather}
                            className={styles["icon"]}
                            src={require(`../media/weather-icons/${convertIcon(forecast && forecast.icon)}`).default}
                        />
                    </div>
                    <div className={styles["wrapper"]}>
                        <p className={styles["temperature"]}>
                            {temperature ? temperature.toFixed(1) : "--"}
                            <span className={styles["unit"]}>°C</span>
                        </p>
                        <p className={styles["description"]}>
                            Dew Point: {dewPoint != null ? dewPoint.toFixed(1) : "--"}
                            <span className={styles["unit"]}> °C</span>
                        </p>
                    </div>
                </div>
                <div className={styles["details"]}>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {windSpeed != null ? windSpeed.toFixed(2) : "--"}
                            <span className={styles["unit"]}> km/h</span>
                        </p>
                        <p className={styles["label"]}>Winds</p>
                    </div>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {barometricPressure != null ? barometricPressure.toFixed(1) : "--"}
                            <span className={styles["unit"]}> mbar</span>
                        </p>
                        <p className={styles["label"]}>Barometer</p>
                    </div>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {humidity != null ? humidity : "--"}
                            <span className={styles["unit"]}> %</span>
                        </p>
                        <p className={styles["label"]}>Humidity</p>
                    </div>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {windGustSpeed != null ? windGustSpeed.toFixed(2) : "--"}
                            <span className={styles["unit"]}> km/h</span>
                        </p>
                        <p className={styles["label"]}>Wind Gusts</p>
                    </div>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {solarRadiation != null ? solarRadiation : "--"}
                            <span className={styles["unit"]}> W/m²</span>
                        </p>
                        <p className={styles["label"]}>Radiation</p>
                    </div>
                    <div className={styles["detail"]}>
                        <p className={styles["value"]}>
                            {dailyRainfall != null ? dailyRainfall.toFixed(1) : "--"}
                            <span className={styles["unit"]}> mm</span>
                        </p>
                        <p className={styles["label"]}>Rain Today</p>
                    </div>
                </div>
                {forecast && (
                    <div className={styles["forecast"]}>
                        <h2>Forecast</h2>
                        <div className={styles["slider"]}>
                            {forecast.periods.map((period, index) => {
                                return (
                                    <div key={period.timestamp} className={styles["day"]}>
                                        <p className={styles["label"]}>
                                            {period.timestamp &&
                                                new Date(period.timestamp * 1000).toLocaleString("en-ES", {
                                                    weekday: "long",
                                                })}
                                        </p>
                                        <img alt={period.weather} className={styles["icon"]} src={require(`../media/weather-icons/${convertIcon(period.icon)}`).default} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
